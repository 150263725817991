import { useState, useEffect, useContext } from 'react';
import styles from './login.module.scss';
import stylesHome from '../home/home.module.scss';
import stylesCandidate from '../candidates/candidateInfo.module.scss';
import Header from '../../components/header/header';

// importando los validadores del formulario
import { 
    validateNotNull,
    validateEmail } from '../../core/validators';

import {ReactComponent as HidePassIcon} from '../../../assets/icons/hide_password.svg';
import {ReactComponent as ShowPassIcon} from '../../../assets/icons/view_password.svg';
import { VerificationModal } from './verificationModal';
import { AppContext } from '../candidates/context/AppProvider';
import { doLogin } from '../../api/userClient';

const LogIn = () => {

    const appContext = useContext(AppContext)

    const [loginInfo, setLoginInfo] = useState({platformId: 1005000008})
    const [errors, setErrors] = useState(false)
    const [passwordVisibilityFlag, setPasswordVisibilityFlag] = useState(true)

    const validationFields = {
        username: [validateEmail, validateNotNull],
        password: [validateNotNull]
    }

    useEffect(() => {
        if (appContext.userData) {
            return appContext.navigate("/home");
        }
    }, [])

    const onChangeInput = ({target}) => {
        setLoginInfo((prevData) => ({...prevData, [target.name]: target.value}) )
    }

    const validateRequest = () => {
        // creando el objeto de errores
        const errorObject = {};
        var helper;
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](loginInfo[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }
        return errorObject
    }

    const onClickVerificyCode = () => {
        appContext.setModalFlag(false)
        appContext.navigate("/password-recovery");
    }

    const onLogin = () => {
        appContext.showLoading(true, appContext.t('loading'), stylesCandidate.no_scroll);
        doLogin(loginInfo).then( response => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            const { data } = response;
            if( response.status === 200 && data && data.code && data.code === 100 ) {
                const userInfo = {
                    token: data.result.authInfo.accessToken,
                    userProfile: {
                        ...data.result.userInfo,
                        id: data.result.userInfo.userId,
                        email: loginInfo.username
                    }
                }
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                appContext.setUserData(userInfo)
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                appContext.navigate("/home");
            } else if( response.status === 202 && data && data.code && data.code === 401 ) {
                appContext.setModalContent( <VerificationModal onClickVerificyCode={onClickVerificyCode} /> )
                    appContext.setModalFlag(true)
                    appContext.setRecoveryInfo({
                        email: loginInfo.username,
                        password: loginInfo.password
                    });
                    appContext.setStepSignUpIndex(1);
            } else if( response.status === 202 && data && data.code ) {
                setErrors({
                    login: [appContext.t( data.code === 403 ? 'login.invalidUser' : 'login.invalidData')],
                    username: [""],
                    password: [""]
                })
            }
        }).catch( error => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if( error.code === 'ERR_NETWORK' ) {
                appContext.displayNotification(null, true, true);
            } else {
                setErrors({
                    login: [error.message],
                    username: [""],
                    password: [""]
                })
            }
        })
    }

    const handleSubmit = event => {
        appContext.displayNotification(null, true, 'hide');
        event.preventDefault();
        const validationErrors = validateRequest();
        // en caso de que no haya errores de validación se hace la petición
        if (Object.keys(validationErrors).length === 0) {
            onLogin();
        } else {
            setErrors(validationErrors)
        }
    }

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ stylesHome.loading_container }>
            <div className={ stylesHome.spinner_container }>
                <div className={ stylesHome.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return  (
        <div className={ styles.signup }>
            { renderLoading() }
            { appContext.modalFlag && appContext.modal }
            <Header />
            { appContext.notification }
            <div className={ styles.wrapper }>
                <div className={ styles.form_wrapper }>
                    <div className={ styles.form_column }>
                        <form className={ styles.form } onSubmit={(event) => handleSubmit(event) }>
                            <div className={ styles.inputs}>
                                <div className={ styles.input_field }>
                                    <label>{appContext.t('login.mail')}</label>
                                    <div>
                                        <input className={ Object.keys(errors).includes("username") ? styles.error : undefined }
                                            name="username" type="text" onChange={ (event) => onChangeInput(event) } />
                                        {
                                            Object.keys(errors).includes("username") && (
                                                <p className={ styles.error_message }>{ errors.username[0] }</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={ styles.input_field }>
                                    <label>{appContext.t('login.pswd')}</label>
                                    <div>
                                        <div className={ styles.password_container + " " + (Object.keys(errors).includes("password") ? styles.error : undefined)}>
                                            <input type={ passwordVisibilityFlag ? "password" : "text" } name="password" onChange={ (event) => onChangeInput(event) } />
                                            <button type='button' onClick={(() => setPasswordVisibilityFlag(!passwordVisibilityFlag))}>
                                                { passwordVisibilityFlag ? <HidePassIcon /> : <ShowPassIcon /> }
                                            </button>
                                        </div>
                                        {
                                            Object.keys(errors).includes("password") && (
                                                <p className={ styles.error_message }>{ errors.password[0] }</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={ styles.buttons }>
                                {
                                    Object.keys(errors).includes("login") && (
                                        <p className={ styles.error_message }>{ errors.login[0] }</p>
                                    )
                                }
                                <a href="/password-recovery">{appContext.t('login.forget')}</a>
                                <button type="submit">{appContext.t('login.button')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogIn