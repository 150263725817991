import { useState, useEffect } from 'react';
import styles from '../candidates/candidateInfo.module.scss';
import stylesHeader from '../../components/header/header.module.scss';
import { getCatalog } from '../../api/catalogClient';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import closeIcon from '../../../assets/icons/close.svg';
import { objEquals } from '../../core/helper';
import { Climate, validateClimate } from './components/climate/Climate';
import { FeedbackComments, validateFeedbackComments } from './components/comments/FeedbackComments';
import { ImprovementActions, validateImprovActions } from './components/improvement/ImprovementActions';
import { InternalNotes, validateInternalNotes } from './components/notes/InternalNotes';
import { CheckupResume } from './components/CheckupResume';
import { executeMultiPromises } from './thunks/loadThunks';
import {
    isGoToImproveSection,
    onSaveClimate,
    onSaveComments,
    onSaveImprovementActions,
    onSaveInternalNotes
} from './thunks/saveThunks';

export const Checkup = ({
    appContext,
    review,
    setReview,
    reviews,
    setReviews,
    setChild,
    catType
}) => {
    const [tap, setTap] = useState(0);
    const [checkup, setCheckup] = useState();
    const [checkupCache, setCheckupCache] = useState();
    const [checkupErrors, setCheckupErrors] = useState(false);
    const [catClasification, setCatClasification] = useState();
    const [catExistRisk, setCatExistRisk] = useState();
    const [catLevelSatisfaction, setCatLevelSatisfaction] = useState();
    const [modeUdpate, setModeUdpate] = useState(false);
    const [modeRead, setModeRead] = useState(true);

    const validateCheckupRequest = () => {
        if( tap === 0 && checkup.followUps ) {
            return validateClimate(tap, checkup);
        } else if( tap === 1 ) {
            return validateClimate(tap, checkup);
        } else if( tap === 3 ) {
            return validateFeedbackComments(checkup);
        } else if( tap === 4 ) {
            return validateImprovActions(checkup);
        } else if( tap === 5 ) {
            return validateInternalNotes(checkup);
        }
        return {};
    }

    const onChangeInput = ( property, value ) => {
        setCheckup({ ...checkup, [property]: value })
    }

    const changeTap = (newTap) => {
        setTap( tap === 1 || tap === 2 ? 0 : (modeUdpate && tap !== 6 && tap !== 0 ? 6 : newTap))
        appContext.contentScrollUp();
    }

    const formSections = [
        <Climate
            appContext={appContext}
            tap={tap}
            checkup={checkup}
            checkupErrors={checkupErrors}
            changeTap={changeTap}
            onChangeInput={onChangeInput}
            catClasification={catClasification}
            catExistRisk={catExistRisk}
            catLevelSatisfaction={catLevelSatisfaction}
            modeResume={modeUdpate}
        />,
        null,
        null,
        <FeedbackComments
            appContext={appContext}
            review={review}
            checkup={checkup}
            checkupErrors={checkupErrors}
            onChangeInput={onChangeInput}
            catType={catType}
            modeResume={modeUdpate}
        />,
        <ImprovementActions
            appContext={appContext}
            checkup={checkup}
            checkupErrors={checkupErrors}
            onChangeInput={onChangeInput}
            modeResume={modeUdpate}
        />,
        <InternalNotes
            appContext={appContext}
            checkup={checkup}
            checkupErrors={checkupErrors}
            onChangeInput={onChangeInput}
            modeResume={modeUdpate}
        />,
        <CheckupResume
            appContext={appContext}
            tap={tap}
            changeTap={changeTap}
            review={review}
            setReview={setReview}
            reviews={reviews}
            setReviews={setReviews}
            checkup={checkup}
            checkupErrors={checkupErrors}
            catClasification={catClasification}
            catExistRisk={catExistRisk}
            catLevelSatisfaction={catLevelSatisfaction}
            onChangeInput={onChangeInput}
            modeRead={modeRead}
            setModeRead={setModeRead}
        />
    ]

    const titles = ['climate', 'climate', 'climate', 'comments', 'improvement', 'notes', 'report']

    useEffect(() => {
        if( review && review.processStatus && review.processStatus.id > 1000530001 ) {
            setModeUdpate(true);
            changeTap(6);
        }
        const sorter = '&sort=id&direction=ASC';
        const requestCatalogs = [
            getCatalog(1000030058, 0, 200, sorter),
            getCatalog(1000030056, 0, 200, sorter),
            getCatalog(1000030057, 0, 200, sorter),
            getCatalog(1000020059, 0, 200, sorter)
        ]
        const functions = [
            setCatClasification,
            setCatExistRisk,
            setCatLevelSatisfaction,
            null
        ]
        const context = {
            "requestArray": requestCatalogs,
            "functions": functions,
            "appContext": appContext,
            "setCheckup": setCheckup,
            "setCheckupCache": setCheckupCache,
            "changeTap": changeTap,
            "style": styles.no_scroll,
            "review": review
        }
        executeMultiPromises(context);
    }, [review])

    useEffect(() => {
        updateNavBarTitle(tap);
    }, [tap])

    const updateNavBarTitle = tap => {
        let menuNavigation = [ ...appContext.menuNavigation ];
        menuNavigation.pop();
        menuNavigation.push(
            {
                label: `${appContext.t('candidate.resume.review.title')} ${review.period.substring(0, review.period.lastIndexOf(' '))}`,
                onEvent: setChild,
                arg: null
            }
        );
        menuNavigation.push({label: appContext.t(`checkUp.${titles[tap]}.title`)});
        appContext.setMenuNavigation(menuNavigation);
    }

    const onSave = () => {
        appContext.setModalFlag(false);
        setModeRead(true);
        setCheckupErrors({});
        const checkupErrors = validateCheckupRequest();
        if (Object.keys(checkupErrors).length === 0) {
            const context = {
                "appContext": appContext,
                "checkup": checkup,
                "setCheckup": setCheckup,
                "setCheckupCache": setCheckupCache,
                "tap": tap,
                "changeTap": changeTap,
                "style": styles.no_scroll,
                "review": review,
                "setReview": setReview,
                "reviews": reviews,
                "setReviews": setReviews,
                "setModeUdpate": setModeUdpate,
                "modeUdpate": modeUdpate
            }
            if( tap === 0 || tap === 1 ) {
                onSaveClimate(context);
            } else if ( tap === 3 ) {
                onSaveComments(context);
            } else if( tap === 4 ) {
                onSaveImprovementActions(context);
            } else if( tap === 5 ) {
                onSaveInternalNotes(context);
            } else if ( tap === 6 ) {

            }
        } else {
            setCheckupErrors(checkupErrors);
        }
    }

    const onCancel = () => {
        appContext.setModalFlag(false);
        if( (!modeUdpate && tap >= 3 && tap <= 5) || (!modeUdpate && tap === 0) ) {
            setChild(null);
        } else if( modeUdpate && (tap === 0 || tap === 3) ) {
            changeTap(6);
        } else if (tap === 1 || (!modeUdpate && tap === 3)) {
            changeTap(0);
        } else if ( tap >= 4 ) {
            if (tap === 5) {
                isGoToImproveSection(review, changeTap);
            } else {
                changeTap(tap-1);
            }
        }
        setModeRead(true);
        appContext.contentScrollUp();
    }

    const onModalDiscard = () => {
        setCheckup(JSON.parse(checkupCache))
        onCancel();
    }

    const modalChangesBase = (title, child) => (
        <div className={ stylesHeader.error_modal }>
            <div className={ stylesHeader.wrapper }>
                <div className={ stylesHeader.content_title }>
                    <p className={ stylesHeader.title }>{ title }</p>
                    <img src={ closeIcon } alt='' onClick={ () => appContext.setModalFlag(false) } />
                </div>
                { child }
                <div className={ stylesHeader.content_buttons }>
                    <div className={ stylesHeader.buttons }>
                        <button className={ stylesHeader.cancel } onClick={ onModalDiscard }>{ appContext.t('checkUp.modalCancel.cancel') }</button>
                        <button className={ stylesHeader.confirm } onClick={ onSave }>{ appContext.t('button.save') }</button>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderModalChanges = () => modalChangesBase(appContext.t('checkUp.modalCancel.title'),
        <p>
            { appContext.t('checkUp.modalCancel.msg1').replace(':section', appContext.t(`checkUp.${titles[tap]}.title`)) }
            <br /><br />
            { appContext.t('checkUp.modalCancel.msg2') }
        </p>
    )

    const onValidateBack = () => {
        if(!objEquals(checkup, JSON.parse(checkupCache))) {
            appContext.setModalFlag(!appContext.modalFlag);
            appContext.setModalContent(renderModalChanges());
        } else {
            onCancel()
        }
    }

    const renderButtons = () => (
        <div className={ styles.buttons_spaced }>
            <div className={ styles.buttons } style={{ padding: '0px' }}>
                <button className={ styles.cancel_button } onClick={ onValidateBack }>{ appContext.t( !modeUdpate ? 'button.cancel' : 'back') }</button>
                <button className={ styles.continue_button } onClick={ onSave }>{ appContext.t( modeUdpate ? 'checkUp.btnSaveChanges' : ( tap === 5 ? 'checkUp.btnFinish' : 'continue')) }</button>
            </div>
        </div>
    )

    const renderBackButton = () => (
        <div className={ styles.backbutton } onClick = { () => changeTap(0) }>
            <img src={ backArrowIcon } alt="" /><span>{ appContext.t('button.back') }</span>
        </div>
    )

    return checkup && (
        <div className={ styles.main } >
            <div className={ styles.candidate_info } >
                <div className={ styles.wrapper }>
                    <div className={ styles.candidate_resume }>
                        <div className={ styles.content_wrapper }>
                            <div className={ styles.candidate_columns }>
                                <div className={ styles.candidate_form } style={{ padding: '0px', width: '100%' }}>
                                    { tap === 2 && renderBackButton() }
                                    { formSections[tap >= 0 && tap <= 2 ? 0 : tap] }
                                    { tap !== 2 && tap < 6 && <hr className={ styles.margin_long } /> }
                                    { tap !== 2 && tap < 6 && renderButtons() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
