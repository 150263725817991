import { useContext } from 'react';
import Axios  from 'axios';
import { AppContext } from '../pages/candidates/context/AppProvider';

function RequestFactory() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const appContext = useContext(AppContext);
    // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const instance = Axios.create({
        baseURL: apiUrl,
        // headers: {
        //     Authorization: `Bearer ${userInfo.token.id_token}`,
        //     "X-Authorization": `Bearer ${userInfo.token.access_token}`,
        //     "Accept-Language": "es-MX"
        // }
    })

    instance.interceptors.response.use(response => response, error => {
        if (error) {
            if (error.response.status === 401) {
                appContext.navigate("/login")
            }
            return Promise.reject(error);
        }
	});

    return instance
}
export default RequestFactory