import { useContext, useEffect, useState } from 'react';
import styles from './candidateInfo.module.scss';
import { getTalent } from '../../api/talentClient';
import { buildCurrencyFormat, buildDateString, objEquals, unsearializaDateString } from '../../core/helper';
import { MONTHS, selectEmtpy } from '../../core/hardcode';
import { AppContext } from './context/AppProvider';
import { defaultStringEmpty } from '../../core/talent-module/helper';
import { academicSorted, setSelectDates } from './components/academic_info/academic_info';
import { CandidateContext } from './context';
import { getCatalog } from '../../api/catalogClient';
import { CandidatePostulation } from './candidatePostulation';
import { getPostulations, getViewers } from '../../api/talentInfoClient';
import { getFeedbacks } from '../../api/feedbackClient';
import { PersonalInfoResumeHeader } from './components/personal_info/personal_info_resume_header';
import { PersonalInfoResumeDetail } from './components/personal_info/personal_info_resume_detail';
import { AssigmentResume } from './components/assigment/assigment_resume';
import { VisibilityResume } from './components/visibility/visibility_resume';
import { WorkExperienceResume } from './components/work_experience/work_experience_resume';
import { AcademicInfoResume } from './components/academic_info/academic_info_resume';
import { CertificationResume } from './components/certifications/certification_resume';
import { LanguagesResume } from './components/languages/languages_resume';
import { SkillsResume } from './components/skills/skills_resume';
import { SoftwareResume } from './components/skills/software_resume';
import moment from 'moment';
import { prepareSkills } from './components/skills/skills';
import { FeedbackList } from '../feedback/FeedbackList';

const CandidateResume = (
    {
        setStepIndex,
        setResumeViewFlag,
        displayNotification,
        candidateMode,
        catClient,
        catUser,
        catCurrencies,
        catSkills,
        catSkillProfile,
        catType
    }
) => {

    const appContext = useContext(AppContext);
    const {
        setCompetition,
        setSkills,
        setSkillsCache,
        setAssigment,
        setAssigmentCache
    } = useContext(CandidateContext);

    const [personalInfo, setPersonalInfo] = useState({})
    const [catStatus, setCatStatus] = useState([]);
    const [status, setStatus] = useState();
    const [tab, setTab] = useState(0);
    const [postulations, setPostulations] = useState([]);
    const [evaluations, setEvaluations] = useState([]);

    const loadCatStatus = response => {
        if( response.status === 200 && response.data && response.data.content && Array.isArray(response.data.content) ) {
            let statusTmp = [];
            const content = candidateMode ? response.data.content.filter( l => l.catalogItemId !== 1000900006 ) : response.data.content;
            content.map( l => {
                const status = { id: l.catalogItemId, optionName: l.valueItem }
                statusTmp.push(status);
            })
            setCatStatus(statusTmp);
            if ( candidateMode ) {
                loadPostulations(response.data.content);
            }
        }
    }

    const loadPostulations = catStatus => {
        getPostulations(appContext.talent.id).then( response => {
            if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
                let postTmp = [];
                response.data.result.map( p => {
                    postTmp.push({
                        id: p.id,
                        name: p.vacancyName,
                        recruitment: p.vacancyStatusId,
                        recruitmentDesc: defaultStringEmpty(p.vacancyStatusName),
                        client: defaultStringEmpty(p.clientName),
                        recruiter: defaultStringEmpty(p.recruiterName),
                        status: defaultStringEmpty(catStatus.find(c => c.catalogItemId === p.statusId ).valueItem),
                        date: buildDateString(p.applicationDate, appContext.t('candidate.resume.postulation.dateWordOf'))
                    });
                })
                setPostulations(postTmp);
            }
        }).catch( error => {
            console.log('Error to load postulations', error);
        })
    }

    const loadEvaluations = response => {
        if( response.status === 200 && response.data && response.data.code === 201 && response.data.result && Array.isArray(response.data.result) ) {
            setEvaluations(response.data.result);
        }
    }

    const getAcademicsSorted = academics => {
        let academicsTmp = [];
        academics.map( academic => {
            const academicWithDates = setSelectDates(academic);
            const startDateStr = objEquals(academicWithDates.fIniMes,selectEmtpy) ? appContext.t('noData.unspec') : `${academicWithDates.fIniMes.description} ${academicWithDates.fIniAnio.description}`;
            const endDateStr = academicWithDates.stillStudy ? appContext.t('noData.inProg') : (( academicWithDates.fTerMes && academicWithDates.fTerAnio ) ? `${academicWithDates.fTerMes.description} ${academicWithDates.fTerAnio.description}` : appContext.t('validator.date') );
            const period = startDateStr === appContext.t('noData.unspec') && endDateStr === appContext.t('noData.inProg') ? appContext.t('candidate.resume.courses.notPeriod') : `${startDateStr} - ${endDateStr}`;
            academicWithDates.startDateParsed =  `${academicWithDates.fIniMes.description} ${academicWithDates.fIniAnio.description}`
            academicWithDates.endDateParsed =  (academicWithDates.endDate ? ((academicWithDates.fTerMes && academicWithDates.fTerAnio ) ? `${academicWithDates.fTerMes.description} ${academicWithDates.fTerAnio.description}` : appContext.t('validator.date')) : appContext.t('noData.inProg'))
            academicWithDates.period = period;
            academicsTmp.push(academicWithDates);
        })
        return academicSorted(academicsTmp);
    }

    const setUserInfo = response => {
        const personalInfo = response.data.result;
        let currency = catCurrencies.length > 0 && personalInfo.currency ? catCurrencies.find( c => c.id === personalInfo.currency ) : '';
        currency = defaultStringEmpty(currency ? currency.description : '');
        const clientFind = catClient.find( c => c.id === personalInfo.clientId )
        const currencyString = personalInfo.salaryMin && personalInfo.salaryMax ? `${buildCurrencyFormat(personalInfo.salaryMin)} - ${buildCurrencyFormat(personalInfo.salaryMax)} (${currency})` : '';
        const profileFind = catSkillProfile.find( c => c.id === personalInfo.profileId );
        const assigmentEndDateStr = personalInfo.assignmentEndDate ? buildDateString(personalInfo.assignmentEndDate, appContext.t('candidate.resume.postulation.dateWordOf'), true) : ( personalInfo.assignmentStartDate ? appContext.t('noData.present') : null);
        const leader = catUser.find( u => u.id === personalInfo.createdById);
        getUserViewers({
            firstName: personalInfo.firstName,
            lastName: personalInfo.lastName,
            position: personalInfo.position,
            description: personalInfo.description,
            phone: personalInfo.phone,
            email: personalInfo.email,
            country: personalInfo.address?.country,
            state: personalInfo.address?.state,
            imgProfile: personalInfo.imgProfile,
            previousJobs: personalInfo.previousJobs,
            educations: getAcademicsSorted(personalInfo.educations),
            courses: personalInfo.courses,
            languages: personalInfo.languages,
            skills: personalInfo.skills,
            statusName: personalInfo.statusName,
            currency: currencyString,
            seniority: personalInfo.jobSeniority,
            profile: profileFind ? profileFind.optionName : '',
            client: clientFind ? clientFind.optionName : '',
            positionArea: personalInfo.positionArea,
            assignmentStartDate: buildDateString(personalInfo.assignmentStartDate, appContext.t('candidate.resume.postulation.dateWordOf'), true),
            assignmentEndDate: assigmentEndDateStr,
            hireDate: buildDateString(personalInfo.hireDate, appContext.t('candidate.resume.postulation.dateWordOf'), true),
            createdById: personalInfo.createdById,
            currentLeader: personalInfo.areaLeader,
            leaderBoss: leader ? leader.optionName : ''
        });
        setStatus({ id: personalInfo.statusId, optionName: personalInfo.statusName })
        const skillsTuned = prepareSkills(personalInfo.skills, catSkills);
        setSkills(skillsTuned);
        setSkillsCache(skillsTuned);
        let leaderArea = catUser.find( u => u.optionName === personalInfo.areaLeader);
        leaderArea = leaderArea ? { id: leaderArea.id, description: leaderArea.optionName } : personalInfo.areaLeader ? { id: -1, description: personalInfo.areaLeader } : selectEmtpy;
        const assigment = {
            firstName: personalInfo.firstName,
            lastName: personalInfo.lastName,
            position: personalInfo.position,
            description: personalInfo.description,
            phone: personalInfo.phone,
            email: personalInfo.email,
            client: clientFind ? { id: clientFind.id, description: clientFind.optionName}  : selectEmtpy,
            clientId: clientFind ? clientFind.id : 0,
            area: personalInfo.positionArea,
            startDate: personalInfo.assignmentStartDate ? moment(personalInfo.assignmentStartDate).format('DD/MM/YYYY') : '',
            endDate: personalInfo.assignmentEndDate ? moment(personalInfo.assignmentEndDate).format('DD/MM/YYYY') : '',
            hireDate: personalInfo.hireDate ? moment(personalInfo.hireDate).format('DD/MM/YYYY') : '',
            leaderArea: leaderArea,
            leaderAreaId: leaderArea.id,
            leaderAreaValue: leaderArea.description,
            leader: leader ? { id: leader.id, description: leader.optionName}  : selectEmtpy,
            leaderId: leader ? leader.id : 0,
        };
        setAssigment(assigment);
        setAssigmentCache(assigment);
    }

    const getUserViewers = personalInfo => {
        if(!candidateMode) {
            getViewers(appContext.talent.id).then( response => {
                if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
                    setPersonalInfo({
                        ...personalInfo,
                        visibility: response.data.result
                    });
                } else {
                    setPersonalInfo(personalInfo);
                }
            }).catch( error => {
                setPersonalInfo(personalInfo);
            })
        } else {
            setPersonalInfo(personalInfo);
        }
    }

    const executeMultiPromises = (requestArray, functions) => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        Promise.all(requestArray).then( responses => {
            responses.forEach((response, index) => {
                functions[index](response);
                if(index === requestArray.length - 1) {
                    appContext.showLoading(false, '', styles.no_scroll);
                }
            })
        }).catch( error => {
            console.log(error);
            if( error.code === 'ERR_NETWORK' ) {
                displayNotification(null, false, false, true);
            }
            appContext.showLoading(false, '', styles.no_scroll);
        });
    }

    useEffect(() => {
        setResumeViewFlag(true);
        setCompetition(null);
        const requestArray = [
            getTalent(appContext.talent.id),
            getCatalog(1000010009, 0 ,200, '&sort=id&direction=ASC')
        ];
        const functions = [
            setUserInfo,
            loadCatStatus
        ]
        if(!candidateMode) {
            requestArray.push(getFeedbacks(appContext.userData.userProfile.id, appContext.talent.id));
            functions.push(loadEvaluations)
        }
        executeMultiPromises(requestArray, functions);
    }, [catCurrencies, catUser])

    const updateView = (index, navLabel) => {
        let menuNavigation = [ ...appContext.menuNavigation ];
        menuNavigation.pop();
        menuNavigation.push(
            {
                label: defaultStringEmpty(`${personalInfo.firstName} ${personalInfo.lastName}`), 
                onEvent: setStepIndex,
                arg: 8
            }
        );
        menuNavigation.push(
            {
                label: navLabel,
                onEvent: setStepIndex,
                arg: index
            }
        );
        appContext.setMenuNavigation(menuNavigation);
        appContext.contentScrollUp();
        setStepIndex(index);
    }

    const getParsedDate = (dateString) => {
        // const date = new Date(dateString);
        // return `${date.toLocaleString('es-ES', { month: 'long' }).charAt(0).toUpperCase()}${date.toLocaleString('es-ES', { month: 'long' }).slice(1)} ${date.getFullYear()}`
        const dateJson = unsearializaDateString(dateString);
        const fMes = MONTHS.find( m => m.id === dateJson.monthId )
        return `${fMes.optionName} ${dateJson.yearDesc}`;
    }

    const renderResume = () => (
        <>
            { <PersonalInfoResumeHeader status={ status } setStatus={ setStatus } displayNotification={ displayNotification } updateView={ updateView } catStatus={ catStatus } /> }
            { <PersonalInfoResumeDetail personalInfo={ personalInfo } updateView={ updateView } candidateMode={ candidateMode } /> }
            { !candidateMode && <hr /> }
            { !candidateMode && <AssigmentResume personalInfo={ personalInfo } updateView={ updateView } /> }
            { !candidateMode && <hr /> }
            { !candidateMode && <VisibilityResume personalInfo={ personalInfo } updateView={ updateView } /> }
            <hr />
            { <WorkExperienceResume personalInfo={ personalInfo } updateView={ updateView } getParsedDate={ getParsedDate } /> }
            <hr />
            { <AcademicInfoResume personalInfo={ personalInfo } updateView={ updateView } /> }
            <hr />
            { <CertificationResume personalInfo={ personalInfo } updateView={ updateView } getParsedDate={ getParsedDate } /> }
            <hr />
            { <LanguagesResume personalInfo={ personalInfo } updateView={ updateView } /> }
            <hr />
            { <SkillsResume personalInfo={ personalInfo } updateView={ updateView } /> }
            <hr />
            { <SoftwareResume personalInfo={ personalInfo } updateView={ updateView } /> }
        </>
    )

    return (
        <div className={ styles.candidate_resume }>
            <div className={ styles.content_wrapper }>
                <div className={ styles.personal_info }>
                    { tab >= 0 && tab <= 1 &&
                        <div className={styles.selector}>
                            <div className={`${styles.selector_pill} ${styles.selector_pill_left} ${ tab === 0 ? styles.selector_selected : undefined }`}onClick={ () => setTab(0) }>{ appContext.t('candidate.resume.infoSection') }</div>
                            <div
                                className={`${styles.selector_pill} ${styles.selector_pill_rigth} ${ tab === 1 ? styles.selector_selected : undefined }`}
                                onClick={ () => setTab(1) }>
                                { `${ appContext.t('candidate.resume.' + (candidateMode ? 'postulation' : 'evaluation') + 'Section') } (${ candidateMode ? postulations.length : evaluations.length})` }
                            </div>
                        </div>
                    }
                    { tab === 0 ? renderResume() : (
                        candidateMode ? <CandidatePostulation postulations={ postulations } />
                        : <FeedbackList
                            evaluations={ evaluations }
                            setEvaluations={ setEvaluations }
                            personalInfo={ personalInfo }
                            tab={ tab }
                            setTab={ setTab }
                            catType={ catType }
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
export default CandidateResume;