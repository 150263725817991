import { useContext, useEffect } from 'react';
import { Menu } from '../../components/menu/Menu';
import styles from './home.module.scss';
import Header from '../../components/header/header';
import {ReactComponent as ArrowMenuIcon } from '../../../assets/icons/arrow_menu.svg';
import { AppContext } from '../candidates/context/AppProvider';
import { Navbar } from '../../components/navbar/Navbar';

export const Home = () => {

    const appContext = useContext(AppContext);

    useEffect(() => {
        // For check session
        // TODO For Public and Private Router
        appContext.checkSession();
    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ styles.loading_container }>
            <div className={ `${styles.spinner_container} ${appContext.showMenu ? styles.spinner_container_short : ''}` }>
                <div className={ styles.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return (
        <div className={ styles.home_container }>

            { appContext.modalFlag && appContext.modal }
            <Header />
            { appContext.notification }
            { appContext.showMenu && 
                <div className={ styles.menu }>
                    <Menu />
                </div>
            }
            { !appContext.showMenu && 
                <div className={ styles.button_menu_hidden } onClick={ () => appContext.setShowMenu(!appContext.showMenu) }>
                    <div className={ `${styles.icon} ${ appContext.showMenu ? styles.svg : ''}` }>
                        <ArrowMenuIcon />
                    </div>
                </div>
            }
            <div className={ `${styles.content} ${!appContext.showMenu ? styles.content_full : ''}` }>
                { renderLoading() }
                { <Navbar /> }
                <div ref={ appContext.contentRef } className={ styles.content_scroll }>{ appContext.homeContent }</div>
            </div>
        </div>
    )
}
