import Axios from "axios";

const feedbackApi = process.env.REACT_APP_FEEDBACK_API;
const userModule = 'user';
const talentModule = 'talent'
const feedbackModule = 'feedback'
const catalogModule = 'catalog';
const checkupModule = 'check-up'
const feedbackComments = 'comments';
const improvementActions = 'improvement-actions';
const internalNotes = 'internal-notes';

export const getFeedbackStatus = () => Axios.get(`${feedbackApi}${catalogModule}/status-process/`);

export const getFeedbacks = (userId, talentId) => Axios.get(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/`);

export const saveFeedback = (userId, talentId, feedback) => Axios.post(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/save`, feedback);

export const  updateFeedbackStatus = (userId, talentId, feedbackId, statusId) => Axios.put(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/status/${statusId}`);

export const getCheckUpQuestions = () => Axios.get(`${feedbackApi}${catalogModule}/talent-check-up-item-types`);

export const getCheckUpFollowUp = () => Axios.get(`${feedbackApi}${catalogModule}/talent-check-up-follow-item-types`);

export const getCheckUpByTalent = (userId, talentId) => Axios.get(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}`);

export const getCheckUpByFeedback = (userId, talentId, feedbackId) => Axios.get(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}`);

export const getCheckUpById = (userId, talentId, checkupId) => Axios.get(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}/${checkupId}`);

export const saveCheckUp = (userId, talentId, checkup) => Axios.post(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}`, { "id": checkup.id, "processStatus": checkup.processStatus });

export const saveCheckUpItem = (userId, talentId, checkupId, request) => Axios.post(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}/${checkupId}/item`, request);

export const saveCheckUpFollowItem = (userId, talentId, checkupId, request) => Axios.post(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}/${checkupId}/follow-item`, request);

export const getComments = (userId, talentId, feedbackId) => Axios.get(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${feedbackComments}`);

export const saveComments = (userId, talentId, feedbackId, request) => Axios.post(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${feedbackComments}`, request);

export const getImprovements = (userId, talentId, feedbackId) => Axios.get(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${improvementActions}`);

export const saveImprovements = (userId, talentId, feedbackId, request) => Axios.post(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${improvementActions}`, request);

export const getInternalNotes = (userId, talentId, checkupId) => Axios.get(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}/${checkupId}/${internalNotes}`);

export const saveInternalNotes = (userId, talentId, checkupId, request) => Axios.post(`${feedbackApi}${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}/${checkupId}/${internalNotes}`, request);