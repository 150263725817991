import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../candidateInfo.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';
import donwloadIcon from '../../../../../assets/icons/file.svg';
import { onClickPreviewCV } from '../../../../core/helper';
import { Select } from '../../../../components/talent-module/searcher/Select';
import { setTalentStatus } from '../../../../api/talentInfoClient';

export const PersonalInfoResumeHeader = (
    {
        status,
        setStatus,
        displayNotification,
        updateView,
        catStatus
    }
) => {

    const appContext = useContext(AppContext);

    const updateStatus = statusId => {
        setTalentStatus(appContext.talent.id, statusId, appContext.userData.userProfile.id).then( response => {
            if( response.status === 200 && response.data && response.data.code === 202 ) {
                displayNotification(`${appContext.t('candidate.resume.status')} ${appContext.t('updated')}`, false, 5000);
            } else {
                console.log('Talent status not to update', response);
            }
        } ).catch( error => {
            console.log('Error to update status', error);
        })
    }

    return (
        <div className={ styles.section_main }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.info.title') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(0, appContext.t('candidate.resume.info.title')) }>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ styles.section_header}>
                <Select
                    name='status'
                    value={ status }
                    setValue={ setStatus }
                    options = { catStatus }
                    placeholder = { '' }
                    noSearcheable
                    onChange={ updateStatus }
                    optRigth
                />
                <button className={ styles.blue } onClick={ () => onClickPreviewCV(appContext.talent.id) }>
                    { appContext.isDesktopOrLaptop && appContext.t('candidate.list.headers.cv') }
                    <img src={ donwloadIcon } alt="" />
                </button>
            </div>
        </div>
    )
}